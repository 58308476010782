.header {
  --header-bg: 45deg;
  background: linear-gradient(var(--header-bg), #ee5753, #5836b3);
  color: #fff;
  text-align: center;
  padding: 100px 0 130px 0;
  position: relative;
  animation: rotate 20s infinite;
}
.header .content {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5));
  width: fit-content;
  margin: auto;
  padding: 40px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
}
.header p {
  font-family: 'Patua One', cursive;
  margin-top: 5px;
}
.header h1 {
  font-size: 3em;
}
.header::after {
  content: '';
  background: linear-gradient(transparent, #fff);
  height: 70px;
  display: block;
  width: 100%;
  clip-path: polygon(100% 1000%, 100% 100%, 00% 10%, 0% 100%);
  position: absolute;
  left: 0;
  bottom: 0;
}
@property --header-bg {
  syntax: '<angle>';
  inherits: false;
  initial-value: 45deg;
}
body {
  background: #fff;
}
.page-content {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  padding: 30px;
  min-height: 600px;
  border-radius: 20px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  background: linear-gradient(135deg, #ffb5b4 0, #eaa5a561 58%);
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  margin-bottom:170px;
}
.question {
  width: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgb(255 231 231));
  outline: none;
  border: none;
  height: 100px;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2)
}
.loading {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(135deg, #ffffff 10%, #ffe2e4 50%, #ffffff 90%);
  background-size: 800px 104px;
}
@keyframes loading {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
.heading {
  font-size: 1.5em;
  color: #fff;
  background: #f46871;
  padding: 0 10px;
  width: fit-content;
  text-align: center;
  margin: 20px auto;
}
.ask-button {
  float: right;
  margin-top: 15px;
  background: #fff;
  border: 0;
  outline: 0;
  border-radius: 10px;
  background: linear-gradient(135deg, rgb(244 104 113), rgb(161 47 47));
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  border-radius: 10px; 
  padding: 10px 15px;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}
.card-question {
  background: #fff;
  float: right;
  margin-top: 20px;
  border-radius: 20px;
  padding: 10px 20px;
  width: fit-content;
  max-width: 90%;
}
.card-reply {
  background: #e37878;
  color: #fff;
  float: left;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px 30px;
  width: fit-content;
  max-width: 90%;
}
.float-left {
  float: left;
  width: 100%;
}
.inner-head {
  float: left; 
  width: 100%;
}